<template>
  <div class="impressum">
    <div class="impressum-head">
  <h2>Impressum</h2>
  <router-link to="/" class="link"><button class="close-btn">&times;</button></router-link>
</div>
  <div class="impressum-body">
    <div class="adress">
      <h4>gemäß § 5 TMG</h4>
      <p>{{ adress[0].name }}</p>
      <p>{{ adress[0].street }}</p>
      <p>{{ adress[0].city }}</p>
    </div>
    <div v-for="impress in impressBody" :key="impressBody.section">
      <h4>{{impress.head}}</h4>
      <p>{{impress.copy}}</p>
    </div>
  </div>
</div>
</template>

<script>
import impressJSON from "@/components/impressum.json";
import adressJSON from "@/components/adress.json"; 
export default {
  name: 'Impressum', 
  data() {
    return {
      impressBody: [],
      adress: []
    }
  },
  computed: {
    impressBody() {
      return impressJSON
    },
    adress(){
      return adressJSON
    }
  }
}
</script>

<style scoped>
.impressum{
  padding: 10px;
  box-sizing: border-box;
}

.impressum-head {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-bottom: 60px;
}

.close-btn {
  text-decoration: none;
}

h4 {
  margin: 30px 0 15px;
}

.adress p {
  margin-bottom: 0;
}

/* DESKTOP ___________________________________________________________________________________________*/
@media only screen and (min-width: 1030px) {

  .impressum{
  padding: 60px;
  box-sizing: border-box;
  margin-bottom: 60px;
}

.impressum-head {
  align-items: center;
}

.impressum-head h1 {
  margin: 0;
}

}

</style>
