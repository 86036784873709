<template>
  <router-view />
 
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');


/* BASE SETTING ___________________________________________________________________________________________*/
:root {
  --primary: #9771FF;
  --secondary: #5CFFD4;
  --grey-primary: #727272;
  --grey-secondary: #c9c9c9;
}

* {

  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: var(--grey-primary);
  font-size: 21px;
  transition: all ease-in-out 0.1s;
  scroll-behavior: smooth;
}

body {
  padding: 0 30px;
}

h1 {
  font-weight: 700;
  font-size: 3em;
  font-style: normal;
  line-height: 1em;
  margin: 0 0 0.5em 0;
  color: var(--primary);
}

h2 {
  font-weight: 700;
  font-size: 2em;
  font-style: normal;
  line-height: 1em;
  margin: 0 0 0px 0;
  color: var(--primary);
}

h3 {
  font-weight: 700;
  font-size: 1.5em;
  font-style: normal;
  line-height: 1em;
  margin: 0 0 0.8em 0;
}

p {
  font-weight: 400;
  font-size: 1em;
  font-style: normal;
  line-height: 1.4em;
  margin: 0 0 1em 0;
}

button {
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  border-radius: 90px;
  font-weight: 700;
  font-size: 1.5em;
  border: 3px solid var(--grey-secondary);
  background-color: white;
  color: var(--grey-secondary)
}

.bottom {
  height: 90px;
  width: 100%;
}

.link {
  text-decoration: none;
}


/* DESKTOP ___________________________________________________________________________________________*/

@media only screen and (min-width: 1030px) {
  body {
    padding: 0 60px;
  }

  button:hover {
    cursor: pointer;
    border-color: var(--secondary);
  }


  .bottom {
    display: none;
  }
}

/* Active Category ___________________________________________________________________________________________*/
.selected {
  border-color: var(--primary);
  color: var(--primary);
}
</style>
