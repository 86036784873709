<template>
  <header>
    <!--HEADER MOBILE-->
    <div class="header-mobile">
      <div class="logo-menue">
        <img src="/logo/studio-senph.svg" alt="studio senph logo" @click="filterProjects()">
        <img :src="menueIcon" alt="" @click="handleFilterOpen()" :class="classIcon">
      </div>
      <Transition name="slide-fade">
        <div class="project-filter-mobile" v-if="filterOpen">
          <button @click="filterProjects('ux'), handleFilterOpen()"
            :class="{ 'selected': isActive('ux') }">ux&nbsp;/&nbsp;web</button>
          <button @click="filterProjects('branding'), handleFilterOpen()"
            :class="{ 'selected': isActive('branding') }">branding</button>
          <button @click="filterProjects('spielwiese'), handleFilterOpen()"
            :class="{ 'selected': isActive('spielwiese') }">spielwiese</button>
          <button @click="filterProjects('about'), handleFilterOpen()"
            :class="{ 'selected': isActive('about') }">about&nbsp;me</button>
        </div>
      </Transition>
    </div>
    <!--HEADER DESKTOP-->
    <div class="header-desktop">
        <div class="project-filter-desktop">
          <img src="/logo/studio-senph.svg" alt="studio senph logo" @click="filterProjects()">
          <button @click="filterProjects('ux')"
            :class="{ 'selected': isActive('ux') }">ux&nbsp;/&nbsp;web</button>
          <button @click="filterProjects('branding')"
            :class="{ 'selected': isActive('branding') }">branding</button>
          <button @click="filterProjects('spielwiese')"
            :class="{ 'selected': isActive('spielwiese') }">spielwiese</button>
          <button @click="filterProjects('about')"
            :class="{ 'selected': isActive('about') }">about&nbsp;me</button>
        </div>
    </div>
  </header>

  <h2 class="headline-text">{{headlineText}}</h2>

  <div class="project-grid-container">
    <div class="project-grid-items" v-for="project in filteredProjects" :key="project.name" >
      <v-lazy-image 
      :src="project['img']" 
      :src-placeholder="project['img-low']"
      :alt="project.name" 
      @click="handleOverlay(project)" />
    </div>
  </div>

  <div class="button-group">
    <a href="https://www.instagram.com/senph.studio/" target="_blanc" class="link">
    <button>instagram</button>
  </a>
  <router-link to="/impressum" class="link">
    <button>impressum</button>
  </router-link>
  </div>

  <div class="bottom"></div>

  <ProjectOverlay v-if="overlay" class="overlay" @update-overlay="handleOverlay" :project="selectedProject"/>

</template>

<script>
import ProjectOverlay from "@/components/ProjectOverlay.vue";
import projectsJSON from "@/components/projects.json";
import vLazyImage from 'v-lazy-image';
export default {
  name: 'Home',
  components: {
    ProjectOverlay,
    vLazyImage
  },
  data() {
    return {
      overlay: false,
      selectedProject: {},
      filterOpen: false,
      menueIcon: "icons/open.svg",
      selectedCategory: null,
      classIcon: null,
      headlineText: "hier ist eine auswahl meiner besten projekte"
    }
  },
 
  methods: {
    filterProjects(category) {
      if (this.selectedCategory === category || category === null) {
        this.selectedCategory = null;
        this.headlineText = "alle projekte";
      } else {
        this.selectedCategory = category;
        if (this.selectedCategory === "ux") this.headlineText = "alle web projekte";
        else if (this.selectedCategory === "branding") this.headlineText = "alle branding projekte";
        else if (this.selectedCategory === "spielwiese") this.headlineText = "alle projekte auf der spielwiese";
        else if (this.selectedCategory === "about") this.headlineText = "erfahre alles über mich"
        else this.headlineText = "alle projekte";
      }
    },
    isActive(category) {
      return this.selectedCategory === category;
    },
 
    handleFilterOpen() {
      this.filterOpen = !this.filterOpen;
      this.menueIcon = this.filterOpen ? "icons/close.svg" : "icons/open.svg";
      this.classIcon = this.filterOpen ? "rotation" : "noRotation";

    },
    handleOverlay(project){
      this.overlay = !this.overlay;
      this.selectedProject = project;
    },
    
  },

  computed: {
    filteredProjects() {
      if (this.selectedCategory) {
        return projectsJSON.filter((project) => project.cat === this.selectedCategory);
      } else {
        return projectsJSON;
      }
    },
    projects() {
      return projectsJSON;
    }
  },
}
</script>

<style scoped>
/* HEADER ___________________________________________________________________________________________*/
header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 0;
  background-color: white;
}

.header-desktop{
  display: none;
}

.header-mobile {
  display: block;
}

.header-mobile img{
    height: 62px;
    width: auto;
  }

  .logo-menue{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.rotation {
    transform: rotate(180deg);
    transition: all 0.6s ease-in-out;
  }

  .noRotation {
    transition: all 0.6s ease-in-out;
  }

.project-filter-mobile {
  padding: 60px 0 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  box-sizing: border-box;
}

.project-grid-items {
  cursor: pointer;
  transition: all ease-in-out 0.8s;
}

/*___________________________________________________________________________________________ HEADER */
.headline-text{
  margin: 30px 0 15px;
}
.project-grid-container img {
  aspect-ratio: 3/2;
  object-fit: cover;
  width: 100%;
  margin-bottom: 30px;
}

/*OVERLAY*/

.overlay {
  top: 0;
  left: 0;
  z-index: 10;
}


.button-group button {
  width: 100%;
  margin: 0 0 15px 0;
}

/* DESKTOP ___________________________________________________________________________________________*/
@media only screen and (min-width: 1030px) {
  .button-group {
    display: flex;

  }
 .button-group  button {
    width: 25vw;
    margin: 0 30px 30px 0;
  }
  .header-desktop{
    display: block;
  } 

  .header-mobile{
    display: none;
  }
 
  .header-desktop{
    display: block;
  }
  .burger-menue {
    display: none;
  }

  .project-grid-container {
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .project-grid-container img {
    margin-bottom: 0;
  }

  .project-filter-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .project-filter-desktop img {
    cursor: pointer;
    width: 15%;
  }
  .project-filter-desktop button {
    width: calc((85% / 4) - 20px);
  }
}

/*------Drop Down Animation ------- */
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
</style>
