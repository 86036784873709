<template>
    <div class="overlay-container">

        <div class="project">
            <div class="content">
                <section class="project-head">
                    <h1 class="project-name">{{ project.name }}</h1>
                    <button @click="handleClick">&times;</button>
                </section>

                <div class="grid-container">
                        <div class="project-images" v-for="image in project.images" :key="image">
                            <v-lazy-image 
                                :src="image['high-res']"
                                :src-placeholder="image['low-res']" 
                                @click="openImageOverlay(project.images.indexOf(image))"
                                alt="project image"
                            />
                        </div>
                </div>
                <p class="project-text">{{ project.text }}</p>
                <p class="project-info">{{ project.info }} <br /> {{ project.team }}</p>
                <a :href="project.link" target="_blank" v-if="checkLink()">&#10132; {{ project.type }}</a>
            </div>
            <div class="bottom"></div>
        </div>


        <div v-if="isOverlayImage" class="image-overlay">
            <div class="overlay-close">
                <button class="overlay-button" @click="closeImageOverlay">&times;</button>
            </div>
            <div class="image-carousel">
                <button @click="previousImage" class="overlay-button arrow-button">&#8592;</button>
                <v-lazy-image 
                    :src="project.images[currentImage]['high-res']"
                    :src-placeholder="project.images[currentImage]['low-res']"
                />
                <button @click="nextImage" class="overlay-button arrow-button">&#8594;</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import vLazyImage from 'v-lazy-image';

export default {
    name: 'ProjectOverlay',
    components: {
        vLazyImage,
    },
    props: {
        project: Object,
    },
    data() {
        return {
            currentImage: null,
            isOverlayImage: false,
        }
    },
    methods: {
        handleClick() {
            this.$emit('update-overlay');
        },
        checkLink() {
            if (this.project.link !== "") {
                return true;
            } else {
                return false;
            }
        },
        openImageOverlay(imageIndex) {
            this.currentImage = imageIndex
            this.isOverlayImage = true;
        },
        closeImageOverlay() {
            this.isOverlayImage = false;
        },

        previousImage() {
            this.currentImage--;
            if (this.currentImage < 0) {
                this.currentImage = this.project.images.length - 1;
            }
        },
        nextImage() {
            this.currentImage++;
            if (this.currentImage >= this.project.images.length) {
                this.currentImage = 0;
            }
        },
    },
}

</script>
  
<style scoped>
a {
    cursor: pointer;
    color: var(--primary);
    text-decoration: none;
    font-weight: 700;
}

.overlay-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project {
    padding: 20px;
    width: 100vw;
    height: 100vh;
    background-color: white;
    overflow-y: scroll;
    box-sizing: border-box;

}

.project-images img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
    margin-bottom: 15px;
}

.project-head {
    display: flex;
    justify-content: space-between;
    align-items: top;
    margin-bottom: 20px;
}

.project-head h1 {
    margin: 0;
}

.project-head img {
    cursor: pointer;
    height: 55px;
    width: auto
}

.project-text {
    margin-top: 30px;
}

.project-info {
    font-weight: 700;
}

.image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(151, 113, 255, 0.95);
    z-index: 80;
}

.overlay-close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
}

.image-carousel {
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.image-carousel img {
    width: 85vw;
    height: 80vh;
    object-fit: contain;
}

.arrow-button {
    display: none;
}

button.overlay-button  {
    background-color: rgba(255, 255, 255, 0);
    border-color: white;
    color: white;
}




@media only screen and (min-width: 1030px) {
    .arrow-button {
        display: block;
    }

    .project {
        padding: 60px;
    }

    .project-images img {
        cursor: pointer;
        margin: 0;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }

    .image-carousel img {
        padding: 0 20px;
    }

    .project-head{
        align-items: center;
    }


button:hover {
    border-color: var(--secondary);
    color: var(--secondary);
}


}
</style>
  